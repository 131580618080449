import React from 'react';

function PrivacyPolicy() {
    return (
        <div style={{ padding: '20px' }}>
            <h1>Privacy Policy for Fast Number Generator</h1>
            
            <h2>Effective Date: February 1, 2024</h2>

            <h3>Introduction</h3>
            <p>
                FastNumGen is committed to protecting your privacy. This privacy policy outlines our practices regarding the collection, use, and disclosure of information for the FastNumGen app.
            </p>

            <h3>Information Collection and Use</h3>
            <p>
                FastNumGen does not collect any personal data or usage data. The app functions as a number generator and does not require or store any user information.
            </p>

            <h3>Data Sharing and Disclosure</h3>
            <p>
                Since no data is collected, there is no sharing or disclosure of user data to any third parties.
            </p>

            <h3>Data Storage and Security</h3>
            <p>
                As FastNumGen does not collect data, there is no storage of user data. Therefore, there are no concerns regarding data storage and security.
            </p>

            <h3>User Rights</h3>
            <p>
                Users have no data within the app; hence, there are no specific rights to exercise regarding personal data.
            </p>

            <h3>Children's Privacy</h3>
            <p>
                FastNumGen does not specifically target children under the age of 13. As the app does not collect personal information, it is compliant with the Children's Online Privacy Protection Act (COPPA).
            </p>
        </div>
    );
}

export default PrivacyPolicy;
