// CustomAmountButton.js
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import StripeBuyButton from './StripeBuyButton';

const CustomAmountButton = ({ publishableKey }) => {
    const [amount, setAmount] = useState('');

    const handleAmountChange = (event) => {
        setAmount(event.target.value);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <TextField
                label="Other Amount ($)"
                type="number"
                value={amount}
                onChange={handleAmountChange}
                inputProps={{ min: "1" }} // Set minimum value
                style={{ marginTop: '10px' }}
            />
            <StripeBuyButton
                publishableKey={publishableKey}
                amount={amount}
                buttonText="Donate Custom Amount"
            />
        </div>
    );
};

export default CustomAmountButton;
