// NumberGenerator.js
import React, { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import StripeBuyButton from './StripeBuyButton';
import CustomAmountButton from './CustomAmountButton';
import appStoreBadge from '../appstore.jpg'; // Import the badge image


const multiplier = 0x5DEECE66Dn;
const addend = 0xBn;
const publishableKey = "pk_live_51OCbWxLZ2XEwfmCN6PuyZyDoJpDSsIN1bB6PgFq0Pxk91wA0uwGKz5DqT562Pw3rJjpZW6ItIcjs74lWpVmR8N9c00SapSFjjQ";


const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const gencode = (seed) => {
    seed = BigInt.asUintN(48, BigInt(seed) ^ multiplier);
    const next = (bits) => {
        const oldseed = seed;
        const nextseed = BigInt.asUintN(48, oldseed * multiplier + addend);
        seed = nextseed;
        return Number(nextseed >> (48n - BigInt(bits)));
    };

    let bits, val;
    do {
        bits = next(31);
        val = bits % 999999;
    } while (bits - val + (999999 - 1) < 0);
    return val;
};

function NumberGenerator() {
    const [seed, setSeed] = useState('');
    const [generatedNumber, setGeneratedNumber] = useState(null);
    const [showDonationOptions, setShowDonationOptions] = useState(false); // New state for showing donation options

    const handleGenerate = () => {
        const number = gencode(seed);
        setGeneratedNumber(number);
    };

    const toggleDonationOptions = () => {
        setShowDonationOptions(!showDonationOptions);
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline /> {/* This helps to apply baseline CSS for the dark theme */}
            <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <TextField
                    label="Enter number"
                    type="number" // Set the type to 'number'
                    variant="outlined"
                    value={seed}
                    onChange={(e) => setSeed(e.target.value)}
                />
                <Button variant="contained" color="primary" onClick={handleGenerate}>
                    Generate Number
                </Button>
                {generatedNumber !== null && (
                    <div>
                        <strong>Generated Number:</strong> {generatedNumber}
                    </div>
                )}
            </div>
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <p>If you found this helpful consider donating!</p>
                <Button variant="contained" color="secondary" onClick={toggleDonationOptions}>
                    Donate
                </Button>
            </div>
            {showDonationOptions && (
                <div style={{ maxWidth: '80%', margin: 'auto', display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px' }}>
                    <StripeBuyButton publishableKey={publishableKey} amount={1} buttonText="Donate $1" />
                    <StripeBuyButton publishableKey={publishableKey} amount={3} buttonText="Donate $3" />
                    <StripeBuyButton publishableKey={publishableKey} amount={5} buttonText="Donate $5" />
                    <CustomAmountButton publishableKey={publishableKey} />
                </div>
            )}
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <a href="https://apps.apple.com/us/app/fast-number-generator/id6477904575?platform=iphone" target="_blank" rel="noopener noreferrer">
                    <img src={appStoreBadge} alt="Download on the App Store" style={{ maxWidth: '80%', height: 'auto' }} />
                </a>
            </div>
        </ThemeProvider>
    );
}

export default NumberGenerator;
