// StripeBuyButton.js
import React from 'react';
import Button from '@mui/material/Button';

const StripeBuyButton = ({ publishableKey, amount, buttonText }) => {
    const handleClick = async () => {
        const stripe = window.Stripe(publishableKey);

        // Fetch the session ID from your backend
        const response = await fetch('/create-checkout-session', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ amount: amount * 100 }) // Convert amount to cents
        });
        const session = await response.json();

        // Redirect to Stripe Checkout
        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });

        if (result.error) {
            console.error(result.error.message);
        }
    };

    return (
        <Button variant="contained" color="primary" onClick={handleClick}>
            {buttonText}
        </Button>
    );
};

export default StripeBuyButton;
